import { CompStats } from "../../DTOs/CompDTOs/CompStatsDTO";
import { ChampImg } from "./ChampImg";
import { CompPart } from "./CompPart";

export const CompCard = (props: CompStats) => {
    let showComp = true;

    if(props.filter.games > props.games){
        showComp = false;
    }else if(props.filter.winrate > props.winrate){
        showComp = false;
    }else if(props.filter.compSize > props.comp.length){
        showComp = false;
    }else if(props.filter.compChamps.length > 0){
        let compMatchCount = 0;
        props.comp.forEach((comp:any) => {
            props.filter.compChamps.forEach((filterChamp:any) => {
                if(comp.champ === filterChamp){
                    compMatchCount++;
                }
            })
        })

        if(compMatchCount !== props.filter.compChamps.length){
            showComp = false;
        }
    }


    if (showComp) {
        return (
            <div className="CompCard">
                <span className="CompCard-Stats">
                    {props.filter.showGames &&
                        <span className="CompCard-Stats-Games">
                            <b>Games: </b>{props.games}
                        </span>
                    }
                    {props.filter.showWinrate &&
                        <span className="CompCard-Stats-Winrate">
                            <b>Winrate: </b>
                            {props.winrate as number >= 50 ?
                                <span className="champion-stats-winrate-positiv">{props.winrate || (props.winrate === 0 ? "0%" : "-")}{(props.winrate && "%") || ""}</span> :
                                <span className="champion-stats-winrate-negativ">{props.winrate || (props.winrate === 0 ? "0%" : "-")}{(props.winrate && "%") || ""}</span>}
                        </span>
                    }
                    {props.filter.showGameTime &&
                        <span className="CompCard-Stats-gameDuration">
                            <b>avg. game time: </b>{props.gameDuration}min
                        </span>
                    }
                </span>
                {(props.filter.showDamage || props.filter.showNames) &&
                    <table className={"CompCard-Comp"}>
                        {
                            props.comp.map((comp: any) => {
                                return (<CompPart key={comp.name + comp.damageDealt + comp.champ}
                                    name={comp.name}
                                    role={comp.role}
                                    champ={comp.champ}
                                    damageDealt={comp.damageDealt}
                                    totalDamageDealt={props.totalDamage}
                                    filter={props.filter}
                                    HandleBan={props.HandleBan}
                                    BanList={props.BanList} version={props.version} />)
                            })
                        }
                    </table>
                }
                {(!props.filter.showDamage && !props.filter.showNames) &&
                    <span className={"CompCard-Comp-small"}>
                        {props.comp.map((comp: any) => {
                            return (<ChampImg name={comp.champ} version={props.version} banList={props.BanList} HandleBan={props.HandleBan} />)
                        })}
                    </span>
                }
            </div>
        );
    } else {
        return (<></>);
    }
}