
import config from '../../assets/config/config.json';

interface imgStats {
  name: string;
  version: string;
  banList: string[]
  HandleBan: (args: string) => void;
  isBanHighlight?: boolean;
  isCompChampSelector?: boolean;
}


export const ChampImg = (props: imgStats) => {


  let inBanList = false;
  let spanClass = "";
  let specialSpanClass = "";
  let imgClass = "champimg-default";

  for(let i = 0; i < props.banList.length; i++){
    if(props.banList[i] === props.name)
      inBanList = true;
  }
  
  if(inBanList && props.isBanHighlight !== true){
    spanClass = "champimg-banspan";
    imgClass = "champimg-ban";
  }
  else{
    spanClass = "";
    imgClass = "champimg-default";
  }

  if(props.isCompChampSelector){
    specialSpanClass = "champimg-compchampselectorspan-special";

    if(inBanList){
      specialSpanClass = ""
      spanClass = "champimg-compchampselectorspan";
      imgClass = "champimg-compchampselector";
      
    }
  }
  

    return (
      <span className={'champimg '+specialSpanClass+spanClass}>
        <img onClick={(e) => props.HandleBan(props.name)} className={imgClass} alt={""} src={config.riot_champ_img_base_url + props.version + "/img/champion/" + props.name + ".png"} />
      </span>)

}