import { useEffect, useState } from "react";
import {
    DndContext,
    closestCenter,
    TouchSensor,
    PointerSensor,
    MouseSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { SortableBanChamp } from "./SortableBanChamp";


interface banStats {
    names: string[];
    version: string;
    HandleBan: (args: string) => void;
}

export const BanHighlighter = (props: banStats) => {
    const [items, setItems] = useState([] as string[]);

    const adjustArrays = (firstArray: string[]): string[] =>{
        let secondArray = items;

        // Wenn das erste Array kürzer ist, entferne alles aus dem zweiten Array,
        // was nicht im ersten Array enthalten ist
        if (firstArray.length < secondArray.length) {
          const filteredSecondArray = secondArray.filter((item) => firstArray.includes(item));
          return filteredSecondArray;
        }
      
        // Wenn das erste Array länger ist, füge dem zweiten Array alle Elemente hinzu,
        // die im ersten Array vorhanden sind, aber nicht im zweiten Array
        const diff = firstArray.filter((item) => !secondArray.includes(item));
        const updatedSecondArray = [...secondArray, ...diff];
        return updatedSecondArray;
      }

    useEffect(() => {
        setItems(adjustArrays(props.names))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.names])

    const sensors = useSensors(
        useSensor(TouchSensor, {
            // Press delay of 250ms, with tolerance of 5px of movement
            activationConstraint: {
                delay: 250,
                tolerance: 5,
            }
        }),
        useSensor(MouseSensor, {
            // Press delay of 250ms, with tolerance of 5px of movement
            activationConstraint: {
                delay: 250,
                tolerance: 5,
            }
        }),
        useSensor(PointerSensor, {
            // Press delay of 250ms, with tolerance of 5px of movement
            activationConstraint: {
                delay: 250,
                tolerance: 5,
            }
        }),
    );

    if ((props.names.length <= 0) || (props.names.length === 1 && props.names[0] === ""))
        return (<></>)
    else {
        return (<span className="banhighlighter">
            <h3>Bans:</h3>
            <span className="banhighlighter-content">
                <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragEnd={handleDragEnd}
                >
                    <SortableContext
                        items={items}
                        strategy={verticalListSortingStrategy}
                    >
                        {items.map(id => <SortableBanChamp version={props.version} BanList={props.names} HandleBan={props.HandleBan} id={id} />)}
                    </SortableContext>
                </DndContext>
                {/* {
                    props.names.map((n) => {
                        return <ChampImg name={n} version={props.version} banList={props.names} HandleBan={props.HandleBan} isBanHighlight={true}/>
                    })
                } */}
            </span>
        </span>)
    }

    function handleDragEnd(event: any) {
        const { active, over } = event;

        if (active.id !== over.id) {
            setItems((items) => {
                const oldIndex = items.indexOf(active.id);
                const newIndex = items.indexOf(over.id);

                return arrayMove(items, oldIndex, newIndex);
            });
        }
    }
}