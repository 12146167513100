import './scss/index.scss';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Header } from './components/basic/Header';
import { Footer } from './components/basic/Footer';
import { Impressum } from './pages/Impressum';
import "primereact/resources/primereact.min.css";                  //core css
import "primeicons/primeicons.css";                                //icons
import CookieConsent from 'react-cookie-consent';
import { HowItWorks } from './pages/HowItWorks';
import { Tracker } from './pages/Tracker';
import { Werbung } from './components/basic/Werbung';
import React from 'react';

function App() {
  return (
    <div className='site'>
      <React.StrictMode>
      <Router>
        <Header />
        <div className="home">
          <div className="home-main">
            <Routes>
              <Route path='/' element={<Tracker />} />
              <Route path='/loltracker/*' element={<Tracker />}>
              <Route path=':id' element={<Tracker />} />
              </Route>
              <Route path='/HowItWorks' element={<HowItWorks />} />
              <Route path='/Impressum' element={<Impressum />} />
            </Routes>
          </div>
          <div className="home-side">
            <Werbung />
          </div>
        </div>
        <CookieConsent
          location="bottom"
          buttonText="I understand"
          cookieName="Cookie"
          expires={150}
        >
          This website uses cookies to enhance the user experience.{" "}
          <span>we use only necessary cookies</span>
        </CookieConsent>
        <Footer />
      </Router>
      </React.StrictMode>
    </div>
  );
}

export default App;
