import React, { useEffect, useState } from 'react';


interface switchStats{
    checked:boolean, onChange:any
}

const Switch = (props:switchStats) => {

    const [cssClass, setCssClass] = useState("react-switch-label-off")

    useEffect(() =>{
        if(props.checked){
            setCssClass("react-switch-label-on")
        }else{
            setCssClass("react-switch-label-off")
        }
    },[props.checked])

  return (
    <>
      <input
        className="react-switch-checkbox"
        id={`react-switch-new`}
        type="checkbox"
        checked={props.checked}
        onChange={props.onChange}
      />
      <label
      className={"react-switch-label " + cssClass}
      htmlFor={`react-switch-new`}
    >
      <span className={`react-switch-button`} />
    </label>
      
      

    </>
  );
};

export default Switch;