import React from "react"
// import postscribe from "postscribe"

export const Werbung = () =>{

    return(<></>);

    // const advRef = useRef(null)

    // useEffect(() => {
    //   if (advRef) {
    //     postscribe(
    //       advRef.current,
    //       "<script data-cfasync='false' type='text/javascript' src='//dexpredict.com/a/display.php?r=6708050'></script>",
    //     )
    //   }
    // }, [advRef])
  
    // return <div className="wer" id="adv" ref={advRef}></div>
}