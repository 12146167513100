
import { RankDTO } from "../../../DTOs/StatsDTOs/RankDTO"
import { EloImage } from "../EloImage"

interface EloCardProps {
    rank: RankDTO;
    quue: string;
}

export const EloCard = (props: EloCardProps) => {

    return (
        <>
            <b>{props.quue} Queue:</b> <span className="data">{props.rank.rank}</span><br />
            <span className="Player-Details-Basic-Data-Parts">
                <EloImage rank={props.rank} quue={props.quue}/>
                {props.rank.rank !== "unranked" &&
                    <span className="Player-Details-Basic-Data-Part">
                        <span className="Player-Details-Basic-Data-Part-Element">
                            <span>LP: </span><span className="data">{props.rank.LP}</span>
                        </span>
                        <span className="Player-Details-Basic-Data-Part-Element"><span>Wins: </span><span className="data">{props.rank.wins}</span></span>
                        <span className="Player-Details-Basic-Data-Part-Element"><span>Losses: </span><span className="data">{props.rank.losses}</span></span>
                    </span>
                }
            </span>
        </>
    )
}