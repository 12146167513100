import { Knob } from "primereact/knob";
import { CompPartStats } from "../../DTOs/CompDTOs/CompPartDTO";
import { ChampImg } from "./ChampImg";
import Tooltip from "rc-tooltip";

export const CompPart = (props: CompPartStats) => {

    let damageDealtProzent = parseFloat(((props.damageDealt / props.totalDamageDealt) * 100).toFixed(2));
    let roleName = "S"
    if (props.role === "TOP") {
        roleName = "Top";
    }
    else if (props.role === "JUNGLE") {
        roleName = "Jungle";
    }
    else if (props.role === "MIDDLE") {
        roleName = "Mid";
    }
    else if (props.role === "BOTTOM") {
        roleName = "Bot";
    }
    else if (props.role === "UTILITY") {
        roleName = "Support";
    }

    return (
        <tr className="CompPart">
            {props.filter.showNames &&
                <td className="CompPart-name">
                    <Tooltip placement="top" overlay={<p>Role: {roleName}</p>}>
                        <span>{props.name}</span>
                    </Tooltip>
                </td>}
                
            <td className="CompPart-champ">
                <Tooltip placement="left" overlay={<p>Role: {roleName}<br/>Champ: {props.champ}{!props.filter.showNames && <><br/>Player: {props.name}</>}</p>}>
                    <span>
                    <ChampImg name={props.champ} version={props.version} banList={props.BanList} HandleBan={props.HandleBan} />
                    </span>
                </Tooltip>
            </td>
            {props.filter.showDamage &&
                <td className="CompPart-damage">
                    <Tooltip placement="top" overlay={<p>Damage: {props.damageDealt}/{props.totalDamageDealt}</p>}>
                        {damageDealtProzent < 10 ?
                            <Knob value={damageDealtProzent} valueTemplate={'{value}%'} readOnly valueColor="#fa7970" rangeColor="#89929b" /> :
                            damageDealtProzent <= 25 ?
                                <Knob value={damageDealtProzent} valueTemplate={'{value}%'} readOnly valueColor="#faa356" rangeColor="#89929b" /> :
                                <Knob value={damageDealtProzent} valueTemplate={'{value}%'} readOnly valueColor="#7ce38b" rangeColor="#89929b" />}
                    </Tooltip>
                </td>
            }
        </tr>
    );
}