import { RankDTO } from "../../DTOs/StatsDTOs/RankDTO";
import iron from "../../assets/imgs/tier/iron.png"
import bronze from "../../assets/imgs/tier/bronze.png"
import silver from "../../assets/imgs/tier/silver.png"
import gold from "../../assets/imgs/tier/gold.png"
import platinum from "../../assets/imgs/tier/platinum.png"
import emerald from "../../assets/imgs/tier/emerald.png"
import diamond from "../../assets/imgs/tier/diamond.png"
import master from "../../assets/imgs/tier/master.png"
import grandmaster from "../../assets/imgs/tier/grandmaster.png"
import challenger from "../../assets/imgs/tier/challenger.png"
import unranked from "../../assets/imgs/tier/unranked.png"

interface EloImageProps {
    rank: RankDTO | null;
    quue: string;
    rankStr?: string | null;
}

export const EloImage = (props: EloImageProps) => {

    
    let tier = "unranked";
    if( props.rank != null){
        tier = props.rank.rank.split(" ")[0];
    }
    else if(props.rankStr != null){
        tier = props.rankStr.split(" ")[0] || "unranked";
    }

    let tierImg = unranked;
    switch (tier) {
        case "IRON":
            tierImg = iron;
            break;

        case "BRONZE":
            tierImg = bronze;
            break;

        case "SILVER":
            tierImg = silver;
            break;

        case "GOLD":
            tierImg = gold;
            break;

        case "PLATINUM":
            tierImg = platinum;
            break;

        case "EMERALD":
            tierImg = emerald;
            break;

        case "DIAMOND":
            tierImg = diamond;
            break;

        case "MASTER":
            tierImg = master;
            break;

        case "GRANDMASTER":
            tierImg = grandmaster;
            break;

        case "CHALLENGER":
            tierImg = challenger;
            break;
    }

    return (
        <span className="tierImage">
            <img src={tierImg} alt={tier} />
        </span>
    );
}