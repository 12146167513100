import {ReactComponent  as Patreon }from '../../assets/svgs/patreon-icon.svg'
import {ReactComponent  as Discord }from '../../assets/svgs/discord-icon.svg'
import { LogoText } from '../special/LogoText';
import { useLocation, Link } from 'react-router-dom';



export const Header = () =>{

    const location = useLocation();
    const getClass = (path:string[]) =>{
       
        let i;
        for (i = 0; i < path.length; i++) {
            if(location.pathname.split("/")[1].localeCompare(path[i]) === 0){
                return "active"
            }
        } 
        return "not-active"
    }
    return(
        <div className="header">
            <span className="header-logo">
            <Link to="/"><LogoText/></Link>
            </span>
            <span className="header-pages">
                <span className={"header-pages-"+getClass(["","loltracker"])}>
                <Link to="/">LoL Tracker</Link>
                </span>
                <span className={"header-pages-"+getClass(["HowItWorks"])}>
                <Link to="/HowItWorks">How it works</Link>
                </span>
            </span>
           <span className="header-links">
                <a href="https://discord.gg/6AgacGPpCP"><Discord/></a>
                <a href="https://patreon.com/RivalScout"><Patreon/></a>
           </span> 
        </div>
    );
}