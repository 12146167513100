import { Accordion, AccordionTab } from 'primereact/accordion';
import HIW_Img_HowToSearch from '../assets/imgs/howitworks/HowToSearch-RivalScout.webp'
import HIW_Img_HowToSearchV2 from '../assets/imgs/howitworks/HowToSearchV2-RivalScout.webp'
import HIW_Img_HowToDeafultPlayerView from '../assets/imgs/howitworks/HowToDeafultPlayerView-RivalScout.webp'
import HIW_Img_HowToPlayerStatsView from '../assets/imgs/howitworks/HowToPlayerStatsView-RivalScout.webp'
import HIW_Img_HowToChampsView from '../assets/imgs/howitworks/HowToChampsView-RivalScout.webp'
import HIW_Img_HowToTeamCompAnalysis from '../assets/imgs/howitworks/HowToTeamCompAnalysis-RivalScout.webp'
import HIW_Img_HowToBan from '../assets/imgs/howitworks/HowToBan-RivalScout.webp'

export const HowItWorks = () => {
    return (
        <div className="howItWorks">
            <div className="howItWorks-content">
                <span className="howItWorks-headline">
                    <h1>How scouting with Rival Scout works</h1>
                </span>
                <Accordion activeIndex={0} className='howItWorks-content-sections'>
                    <AccordionTab header="How to search your rivals" className='howItWorks-content-sections-tab'>
                        <span className='howItWorks-content-sections-tab-content'>
                            <p>
                                To search for players in RivalScout, simply enter them in the search field and separate them with a comma.
                                Then select your region and your search period.
                            </p>
                            <img src={HIW_Img_HowToSearch} alt='How to Search Img' />
                            <p>
                                If you want to refine the search, you can also search for the last queue types, these are limited to 100 games each. They are sorted as follows:
                            </p>
                                <span className='center'>
                                    <table>
                                        <tbody>
                                        <tr>
                                            <td><b>N</b>ormal</td>
                                            <td>&#8594;</td>
                                            <td>Draft Pick</td>
                                        </tr>
                                        <tr>
                                            <td><b>S</b>olo</td>
                                            <td>&#8594;</td>
                                            <td>Ranked Solo/Duo</td>
                                        </tr>
                                        <tr>
                                            <td><b>F</b>lex</td>
                                            <td>&#8594;</td>
                                            <td>Ranked Flex</td>
                                        </tr>
                                        <tr>
                                            <td><b>C</b>lash</td>
                                            <td>&#8594;</td>
                                            <td>games played during Clash</td>
                                        </tr>
                                        <tr>
                                            <td><b>T</b>ournament</td>
                                            <td>&#8594;</td>
                                            <td>games created through a Tournament key</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </span>
                            <img src={HIW_Img_HowToSearchV2} alt='How to Search v2 Img' />
                            <p>
                                To save your search or send it to your friends, you can copy a link by clicking the "Copy Search Link" button.
                                You can easily restore your search later by using the generated link.
                            </p>
                        </span>
                    </AccordionTab>
                    <AccordionTab header="How to Player analysis" className='howItWorks-content-sections-tab'>
                        <span className='howItWorks-content-sections-tab-content'>
                            <p>
                            After you have searched for the requested players in the "Player analysis" they will be displayed.<br/>
                            Here you can see all the important information about the players. With the role icons you can show or hide the data of the role.<br/>
                            The percentage display next to it shows how much the role was played. If you hover over the display you will see more information about the role.<br/>
                            The color gives information about the win rate. The colors are interpreted as follows:
                            </p>
                                    <table>
                                    <tbody>
                                        <tr>
                                            <td><b className='red'>Red</b></td>
                                            <td>&#8594;</td>
                                            <td>Bad</td>
                                        </tr>
                                        <tr>
                                            <td><b className='orange'>Orange</b></td>
                                            <td>&#8594;</td>
                                            <td>medium</td>
                                        </tr>
                                        <tr>
                                            <td><b className='green'>Green</b></td>
                                            <td>&#8594;</td>
                                            <td>Good</td>
                                        </tr>
                                        </tbody>
                                    </table>
                            <p>
                            Under the general player information the most played champions are displayed. <br/>
                            The filters on the side can be used to fine-tune the way the champions are displayed<br/>
                            Via the + next to the champions the display can be extended to show all played champions
                            </p>
                            <img src={HIW_Img_HowToDeafultPlayerView} alt='How to Deafult Player View Img' />
                            <p>
                            The + next to the player data can be used to display additional information.<br/>
                            Here are the detailed "Player Stats" and detailed "Champ Stats".<br/><br/>
                            In the "Player Stats" the name, the level, the rank statistics in Solo/Duo and Flex as well as the 5 champions with the highest mastery are displayed.
                            </p>
                            <img src={HIW_Img_HowToPlayerStatsView} alt='How to Player Stats View Img' />
                            <p>
                            In the "Champ Stats" a detailed display of all played champions divided by played role is shown.<br/>
                            In addition, the statistics for each queue are displayed separately.<br/>
                            Also here the filters can be used to refine the champion display and or to show or hide additional data.
                            </p>
                            <img src={HIW_Img_HowToChampsView} alt='How to Champs View Img' />
                        </span>
                    </AccordionTab>
                    <AccordionTab header="How to Team-Comp analysis" className='howItWorks-content-sections-tab'>
                        <span className='howItWorks-content-sections-tab-content'>
                            <p>
                            If you have searched for at least 2 players, a team comp analysis will be performed, which will give you all combinations of champions that have been played together several times.<br/>
                            In addition, for each combination the winrate, the damage percentage of the champions within their team and how often these combinations were played are displayed.<br/>
                            You can refine the view of team combinations by using the filters on the side.
                            </p>
                            <img src={HIW_Img_HowToTeamCompAnalysis} alt='How to Team Comp Analysis Img' />
                        </span>
                    </AccordionTab>
                    <AccordionTab header="How to prepare Bans" className='howItWorks-content-sections-tab'>
                        <span className='howItWorks-content-sections-tab-content'>
                            <p>
                            To prepare your bans you can press on the images of the champions, then a "Bans" screen will appear. All champions that are already in the ban list will be highlighted in red.<br/>
                            By clicking on champions that are marked red or are displayed in the ban list, they can be removed again.
                            </p>
                            <img src={HIW_Img_HowToBan} alt='How to Ban Img' />
                            <p>
                            To edit the order of the ban list, you can hold down the champions within the list and drag them to the desired position.<br/>
                            Before you move the champions you have to press and hold for a short time.
                            </p>
                        </span>
                    </AccordionTab>
                </Accordion>

            </div>
        </div>
    );
}