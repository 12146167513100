import React from 'react';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import { ChampImg } from './ChampImg';


interface props{
    id: string;
    version:string;
    HandleBan: (args: string) => void;
    BanList: string[]; 
}

export const SortableBanChamp = (props: props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({id: props.id});
  
  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };
  
  return (
    <span ref={setNodeRef} style={style} {...attributes} {...listeners} className="banhighlighter-content">
      <ChampImg name={props.id} version={props.version} banList={props.BanList} HandleBan={props.HandleBan} isBanHighlight={true}/>
    </span>
  );
}