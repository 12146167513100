import { champStats } from "../../DTOs/ChampionDTO";
import { ChampImg } from "./ChampImg";
import img_top from '../../assets/imgs/roles/Position_Top.png';
import img_jungle from '../../assets/imgs/roles/Position_Jungle.png';
import img_mid from '../../assets/imgs/roles/Position_Mid.png';
import img_bot from '../../assets/imgs/roles/Position_Bot.png';
import img_support from '../../assets/imgs/roles/Position_Support.png';



export const ChampionStats = (props: champStats) => {

  let roleImg = img_top;

  if (props.role === "TOP")
    roleImg = img_top
  else if (props.role === "JUNGLE")
    roleImg = img_jungle
  else if (props.role === "MIDDLE")
    roleImg = img_mid
  else if (props.role === "UTILITY")
    roleImg = img_support
  else if (props.role === "BOTTOM")
    roleImg = img_bot
  else
    return <></>

  return (<div className="champion">
    <span className="champion-head">
      <ChampImg name={props.champ} version={props.version} banList={props.BanList} HandleBan={props.HandleBan}/>
      <span className="champion-head-name">{props.champ}</span>
      <img src={roleImg} alt={"lane"} />
    </span>
    {(props.showStats.games ||
      props.showStats.winrate ||
      props.showStats.kda ||
      props.showStats.kills ||
      props.showStats.deaths ||
      props.showStats.assists ||
      props.showStats.gold ||
      props.showStats.cs ||
      props.showStats.visionScore) &&
      <span className="champion-stats">

        <table>
          <thead>
            <tr>
              <th className="champion-stats-first"></th>
              {props.filterStats.normalQ !== false && <th>N</th>}
              {props.filterStats.soloQ !== false &&<th>S</th>}
              {props.filterStats.flexQ !== false &&<th>F</th>}
              {props.filterStats.clash !== false &&<th>C</th>}
              {props.filterStats.tournament !== false &&<th>T</th>}
            </tr>
          </thead>
          <tbody>
            {props.showStats.games &&
              <tr className="champion-stats-games">
                <td className="champion-stats-first">Games:</td>
                {props.filterStats.normalQ !== false && <td>{props.normalQ?.games || (props.normalQ?.games === 0 ? "0" : "-")}</td>}
                {props.filterStats.soloQ !== false && <td>{props.soloQ?.games || (props.soloQ?.games === 0 ? "0" : "-")}</td>}
                {props.filterStats.flexQ !== false && <td>{props.flexQ?.games || (props.flexQ?.games === 0 ? "0" : "-")}</td>}
                {props.filterStats.clash !== false && <td>{props.clash?.games || (props.clash?.games === 0 ? "0" : "-")}</td>}
                {props.filterStats.tournament !== false && <td>{props.tournament?.games || (props.tournament?.games === 0 ? "0" : "-")}</td>}
              </tr>}
            {props.showStats.winrate &&
              <tr className="champion-stats-winrate">
                <td className="champion-stats-first">Winrate:</td>
                {props.filterStats.normalQ !== false && (props.normalQ?.winrate as number >= 50 ?
                  <td className="champion-stats-winrate-positiv">{props.normalQ?.winrate || (props.normalQ?.winrate === 0 ? "0%" : "-")}{(props.normalQ?.winrate && "%") || ""}</td> :
                  <td className="champion-stats-winrate-negativ">{props.normalQ?.winrate || (props.normalQ?.winrate === 0 ? "0%" : "-")}{(props.normalQ?.winrate && "%") || ""}</td>)}
                {props.filterStats.soloQ !== false && (props.soloQ?.winrate as number >= 50 ?
                  <td className="champion-stats-winrate-positiv">{props.soloQ?.winrate || (props.soloQ?.winrate === 0 ? "0%" : "-")}{(props.soloQ?.winrate && "%") || ""}</td> :
                  <td className="champion-stats-winrate-negativ">{props.soloQ?.winrate || (props.soloQ?.winrate === 0 ? "0%" : "-")}{(props.soloQ?.winrate && "%") || ""}</td>)}
                {props.filterStats.flexQ !== false && (props.flexQ?.winrate as number >= 50 ?
                  <td className="champion-stats-winrate-positiv">{props.flexQ?.winrate || (props.flexQ?.winrate === 0 ? "0%" : "-")}{(props.flexQ?.winrate && "%") || ""}</td> :
                  <td className="champion-stats-winrate-negativ">{props.flexQ?.winrate || (props.flexQ?.winrate === 0 ? "0%" : "-")}{(props.flexQ?.winrate && "%") || ""}</td>)}
                {props.filterStats.clash !== false && (props.clash?.winrate as number >= 50 ?
                  <td className="champion-stats-winrate-positiv">{props.clash?.winrate || (props.clash?.winrate === 0 ? "0%" : "-")}{(props.clash?.winrate && "%") || ""}</td> :
                  <td className="champion-stats-winrate-negativ">{props.clash?.winrate || (props.clash?.winrate === 0 ? "0%" : "-")}{(props.clash?.winrate && "%") || ""}</td>)}
                {props.filterStats.tournament !== false && (props.tournament?.winrate as number >= 50 ?
                  <td className="champion-stats-winrate-positiv">{props.tournament?.winrate || (props.tournament?.winrate === 0 ? "0%" : "-")}{(props.tournament?.winrate && "%") || ""}</td> :
                  <td className="champion-stats-winrate-negativ">{props.tournament?.winrate || (props.tournament?.winrate === 0 ? "0%" : "-")}{(props.tournament?.winrate && "%") || ""}</td>)}
              </tr>}
            {props.showStats.kda &&
              <tr className="champion-stats-kda">
                <td className="champion-stats-first">K/D/A:</td>
                {props.filterStats.normalQ !== false && (props.normalQ?.kda as number >= 1 ?
                  props.normalQ?.kda as number >= 3 ?
                    <td className="champion-stats-kda-positiv">{props.normalQ?.kda || (props.normalQ?.kda === 0 ? "0" : "-")}</td> :
                    <td className="champion-stats-kda-neutral">{props.normalQ?.kda || (props.normalQ?.kda === 0 ? "0" : "-")}</td> :
                  <td className="champion-stats-kda-negativ">{props.normalQ?.kda || (props.normalQ?.kda === 0 ? "0" : "-")}</td>)}
                {props.filterStats.soloQ !== false && (props.soloQ?.kda as number >= 1 ?
                  props.soloQ?.kda as number >= 3 ?
                    <td className="champion-stats-kda-positiv">{props.soloQ?.kda || (props.soloQ?.kda === 0 ? "0" : "-")}</td> :
                    <td className="champion-stats-kda-neutral">{props.soloQ?.kda || (props.soloQ?.kda === 0 ? "0" : "-")}</td> :
                  <td className="champion-stats-kda-negativ">{props.soloQ?.kda || (props.soloQ?.kda === 0 ? "0" : "-")}</td>)}
                {props.filterStats.flexQ !== false && (props.flexQ?.kda as number >= 1 ?
                  props.flexQ?.kda as number >= 3 ?
                    <td className="champion-stats-kda-positiv">{props.flexQ?.kda || (props.flexQ?.kda === 0 ? "0" : "-")}</td> :
                    <td className="champion-stats-kda-neutral">{props.flexQ?.kda || (props.flexQ?.kda === 0 ? "0" : "-")}</td> :
                  <td className="champion-stats-kda-negativ">{props.flexQ?.kda || (props.flexQ?.kda === 0 ? "0" : "-")}</td>)}
                {props.filterStats.clash !== false && (props.clash?.kda as number >= 1 ?
                  props.clash?.kda as number >= 3 ?
                    <td className="champion-stats-kda-positiv">{props.clash?.kda || (props.clash?.kda === 0 ? "0" : "-")}</td> :
                    <td className="champion-stats-kda-neutral">{props.clash?.kda || (props.clash?.kda === 0 ? "0" : "-")}</td> :
                  <td className="champion-stats-kda-negativ">{props.clash?.kda || (props.clash?.kda === 0 ? "0" : "-")}</td>)}
                {props.filterStats.tournament !== false && (props.tournament?.kda as number >= 1 ?
                  props.tournament?.kda as number >= 3 ?
                    <td className="champion-stats-kda-positiv">{props.tournament?.kda || (props.tournament?.kda === 0 ? "0" : "-")}</td> :
                    <td className="champion-stats-kda-neutral">{props.tournament?.kda || (props.tournament?.kda === 0 ? "0" : "-")}</td> :
                  <td className="champion-stats-kda-negativ">{props.tournament?.kda || (props.tournament?.kda === 0 ? "0" : "-")}</td>)}
              </tr>}
            {props.showStats.kills &&
              <tr className="champion-stats-kills">
                <td className="champion-stats-first">Kills:</td>
                {props.filterStats.normalQ !== false && <td>{props.normalQ?.kills || (props.normalQ?.kills === 0 ? "0" : "-")}</td>}
                {props.filterStats.soloQ !== false && <td>{props.soloQ?.kills || (props.soloQ?.kills === 0 ? "0" : "-")}</td>}
                {props.filterStats.flexQ !== false && <td>{props.flexQ?.kills || (props.flexQ?.kills === 0 ? "0" : "-")}</td>}
                {props.filterStats.clash !== false && <td>{props.clash?.kills || (props.clash?.kills === 0 ? "0" : "-")}</td>}
                {props.filterStats.tournament !== false && <td>{props.tournament?.kills || (props.tournament?.kills === 0 ? "0" : "-")}</td>}
              </tr>}
            {props.showStats.deaths &&
              <tr className="champion-stats-deaths">
                <td className="champion-stats-first">Deaths:</td>
                {props.filterStats.normalQ !== false && <td>{props.normalQ?.deaths || (props.normalQ?.deaths === 0 ? "0" : "-")}</td>}
                {props.filterStats.soloQ !== false && <td>{props.soloQ?.deaths || (props.soloQ?.deaths === 0 ? "0" : "-")}</td>}
                {props.filterStats.flexQ !== false && <td>{props.flexQ?.deaths || (props.flexQ?.deaths === 0 ? "0" : "-")}</td>}
                {props.filterStats.clash !== false && <td>{props.clash?.deaths || (props.clash?.deaths === 0 ? "0" : "-")}</td>}
                {props.filterStats.tournament !== false && <td>{props.tournament?.deaths || (props.tournament?.deaths === 0 ? "0" : "-")}</td>}
              </tr>}
            {props.showStats.assists &&
              <tr className="champion-stats-assists">
                <td className="champion-stats-first">Assists:</td>
                {props.filterStats.normalQ !== false && <td>{props.normalQ?.assists || (props.normalQ?.assists === 0 ? "0" : "-")}</td>}
                {props.filterStats.soloQ !== false && <td>{props.soloQ?.assists || (props.soloQ?.assists === 0 ? "0" : "-")}</td>}
                {props.filterStats.flexQ !== false && <td>{props.flexQ?.assists || (props.flexQ?.assists === 0 ? "0" : "-")}</td>}
                {props.filterStats.clash !== false && <td>{props.clash?.assists || (props.clash?.assists === 0 ? "0" : "-")}</td>}
                {props.filterStats.tournament !== false && <td>{props.tournament?.assists || (props.tournament?.assists === 0 ? "0" : "-")}</td>}
              </tr>}
            {props.showStats.gold &&
              <tr className="champion-stats-gold">
                <td className="champion-stats-first">Gold/min:</td>
                {props.filterStats.normalQ !== false && <td>{props.normalQ?.goldPerMinute || (props.normalQ?.goldPerMinute === 0 ? "0" : "-")}</td>}
                {props.filterStats.soloQ !== false && <td>{props.soloQ?.goldPerMinute || (props.soloQ?.goldPerMinute === 0 ? "0" : "-")}</td>}
                {props.filterStats.flexQ !== false && <td>{props.flexQ?.goldPerMinute || (props.flexQ?.goldPerMinute === 0 ? "0" : "-")}</td>}
                {props.filterStats.clash !== false && <td>{props.clash?.goldPerMinute || (props.clash?.goldPerMinute === 0 ? "0" : "-")}</td>}
                {props.filterStats.tournament !== false && <td>{props.tournament?.goldPerMinute || (props.tournament?.goldPerMinute === 0 ? "0" : "-")}</td>}
              </tr>}
            {props.showStats.cs &&
              <tr className="champion-stats-cs">
                <td className="champion-stats-first">CS/min:</td>
                {props.filterStats.normalQ !== false && <td>{props.normalQ?.csPerMinute || (props.normalQ?.csPerMinute === 0 ? "0" : "-")}</td>}
                {props.filterStats.soloQ !== false && <td>{props.soloQ?.csPerMinute || (props.soloQ?.csPerMinute === 0 ? "0" : "-")}</td>}
                {props.filterStats.flexQ !== false && <td>{props.flexQ?.csPerMinute || (props.flexQ?.csPerMinute === 0 ? "0" : "-")}</td>}
                {props.filterStats.clash !== false && <td>{props.clash?.csPerMinute || (props.clash?.csPerMinute === 0 ? "0" : "-")}</td>}
                {props.filterStats.tournament !== false && <td>{props.tournament?.csPerMinute || (props.tournament?.csPerMinute === 0 ? "0" : "-")}</td>}
              </tr>}
              {props.showStats.visionScore &&
              <tr className="champion-stats-visionScore">
                <td className="champion-stats-first">Vision:</td>
                {props.filterStats.normalQ !== false && <td>{props.normalQ?.visionScore || (props.normalQ?.visionScore === 0 ? "0" : "-")}</td>}
                {props.filterStats.soloQ !== false && <td>{props.soloQ?.visionScore || (props.soloQ?.visionScore === 0 ? "0" : "-")}</td>}
                {props.filterStats.flexQ !== false && <td>{props.flexQ?.visionScore || (props.flexQ?.visionScore === 0 ? "0" : "-")}</td>}
                {props.filterStats.clash !== false && <td>{props.clash?.visionScore || (props.clash?.visionScore === 0 ? "0" : "-")}</td>}
                {props.filterStats.tournament !== false && <td>{props.tournament?.visionScore || (props.tournament?.visionScore === 0 ? "0" : "-")}</td>}
              </tr>}
          </tbody>
        </table>
      </span>}

  </div>
  );
}